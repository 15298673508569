import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Link, graphql, StaticQuery } from 'gatsby';

const Sitemap = () => (
	<>
		<Layout>
			<SEO
				title="Sitemap"
				lang="fr"
				url="/sitemap"
			/>

			<div className="box-container first">
				<div className="content-block">
					<div className="content-block-bg" />
					<h2>Sitemap</h2>
					<ul>
						<li><Link to="/">Accueil</Link></li>
						<li><Link to="/garage">Garage</Link></li>
						<li><Link to="/atelier">Atelier</Link></li>
						<li><Link to="/customs">Customs</Link></li>
						<li><Link to="/occasions">Occasions</Link></li>
						<li><Link to="/contact">Contact</Link></li>
						<li><Link to="/privacy-policies">Politique de protection des données</Link></li>
					</ul>
				</div>
			</div>
		</Layout>
	</>
);

export default Sitemap
